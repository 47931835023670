<template>
  <div>
    <vs-popup title="Tambah User" class="sm:popup-w-50" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>

        <div class="vx-row mb-4">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Tipe *</label>
            <vs-select class="w-full" v-model="data.type">
              <vs-select-item v-for="(it, index) in ['STAF', 'REKANAN']" :key="index" :value="it" :text="it"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Source *</label>
            <div class="flex items-center justify-center mt-3">
              <vs-radio class="flex-1" vs-name="source" vs-value="NEW" v-model="data.source" :disabled="data.type === 'REKANAN'">New</vs-radio>
              <vs-radio class="flex-1" vs-name="source" vs-value="EXISTING" v-model="data.source">Existing</vs-radio>
            </div>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="con-vs-alert con-vs-alert-primary con-icon">
              <div class="vs-alert con-icon">
                <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
                <span v-if="data.type === 'STAF' && data.source === 'NEW'">Data Staf yang ditambahkan melalui fitur ini akan ditambahkan sebagai master data Staf.</span>
                <span v-if="data.type === 'STAF' && data.source === 'EXISTING'">Silahkan pilih data Staf pada master data. Hanya data yang belum memiliki username yang dapat dipilih.</span>
                <span v-if="data.type === 'REKANAN' && data.source === 'EXISTING'">Silahkan pilih data Rekanan pada master data. Hanya data yang belum memiliki username yang dapat dipilih.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="vx-row mb-3" v-if="data.source === 'NEW'">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label="NIK *" v-model="data.nik"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label="Nama *" v-model="data.nama"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Atasan *</label>
            <div @click="modalAtasan.active = true">
              <vx-input-group>
                <vs-input placeholder="Pilih Atasan" v-model="data.nama_atasan" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>

        <div class="vx-row mb-3" v-if="data.type === 'STAF' && data.source === 'EXISTING'">
          <div class="vx-col w-full">
            <label class="ml-1 text-xs">Staf *</label>
            <div @click="modalStaf.active = true">
              <vx-input-group>
                <vs-input placeholder="Pilih Staf" :value="modalStaf.selected.nama"/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>

        <div class="vx-row mb-3" v-if="data.type === 'REKANAN' && data.source === 'EXISTING'">
          <div class="vx-col w-full">
            <label class="ml-1 text-xs">Rekanan *</label>
            <div @click="modalRekanan.active = true">
              <vx-input-group>
                <vs-input placeholder="Pilih Rekanan" :value="modalRekanan.selected.nama" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Role *</label>
            <vs-select autocomplete class="w-full" v-model="data.id_roles" :disabled="data.type === 'REKANAN'">
              <vs-select-item v-for="(it, index) in roles" :key="index" :value="it.id" :text="it.name"/>
            </vs-select>
          </div>
        </div>

        <vs-divider class="mt-base">Login Credentials</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" label="Username *" autocomplete="new-password" v-model="data.username"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock" label="Password *" autocomplete="new-password" v-model="data.password"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
                title="Pilih Data Atasan"
                :active="modalAtasan.active"
                v-on:update:active="modalAtasan.active = $event">
        <Atasan :selectable="true" @selected="onSelectedModalAtasan"/>
      </vs-popup>

      <vs-popup class="sm:popup-w-50 popup-content-no-padding"
                title="Pilih Data Staf"
                :active="modalStaf.active"
                v-on:update:active="modalStaf.active = $event">
        <Staf v-if="isActive" :selectable="true" :externalFilter="filterStaf" @selected="onSelectedModalStaf"/>
      </vs-popup>

      <vs-popup class="sm:popup-w-50 popup-content-no-padding"
                title="Pilih Data Rekanan"
                :active="modalRekanan.active"
                v-on:update:active="modalRekanan.active = $event">
        <Rekanan v-if="isActive" :selectable="true" :externalFilter="filterRekanan" @selected="onSelectedModalRekanan"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import AclRepository from '@/repositories/general/acl-repository'
import _ from 'lodash'

export default {
  name: 'UserAdd',
  props: ['isActive'],
  components: {
    Atasan: () => import('@/views/pages/master/staf/Staf'),
    Staf: () => import('@/views/pages/master/staf/Staf'),
    Rekanan: () => import('@/views/pages/master/rekanan/Rekanan'),
    ValidationErrors: () => import('@/views/components/validation-errors/ValidationErrors')
  },
  computed: {
    filterStaf () {
      return { username: 'null' }
    },
    filterRekanan () {
      return { username: 'null' }
    },
    roles () {
      return this.$store.state.general.accessControl.roles
    }
  },
  watch: {
    'data.type' (type) {
      if (type === 'STAF') {
        this.data.source = 'NEW'
        this.data.id_roles = null
      }
      if (type === 'REKANAN') {
        this.data.source = 'EXISTING'
        this.data.id_roles = (_.find(this.roles, item => item.kode === 'RKN').id) || null
      }
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      modalAtasan: {
        active: false
      },
      modalStaf: {
        active: false,
        selected: {}
      },
      modalRekanan: {
        active: false,
        selected: {}
      },
      data: {
        type: 'STAF',
        source: 'NEW'
      }
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      AclRepository.createUser(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSelectedModalAtasan (item) {
      this.data.id_atasan = item.id
      this.data.nama_atasan = item.nama
      this.modalAtasan.active = false
    },

    onSelectedModalStaf (item) {
      this.data.id_staf = item.id
      this.modalStaf.selected = item
      this.modalStaf.active = false
    },

    onSelectedModalRekanan (item) {
      this.data.id_rekanan = item.id
      this.modalRekanan.selected = item
      this.modalRekanan.active = false
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
